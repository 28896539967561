import BasePlugin from '../BasePlugin'

export default class RunAsyncSetCommissioningByIds extends BasePlugin {
  async execute () {
    let components = this.context.getModel()
    let requests = components.project
    let adjustmentRequests = components.correct
    if (requests === undefined) {
      requests = []
    } else {
      requests = requests.split`,`.map(x => +x).filter(function (x) {
        return !Number.isNaN(x)
      })
    }
    if (adjustmentRequests === undefined) {
      adjustmentRequests = []
    } else {
      adjustmentRequests = adjustmentRequests.split`,`.map(x => +x).filter(function (x) {
        return !Number.isNaN(x)
      })
    }
    if (requests.length === 0 && adjustmentRequests.length === 0) {
      this.context.$msgbox({
        type: 'info',
        message: 'Необходимо заполнить хотя бы одну строку параметров'
      })
      return
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetCommissioningByIdsAsyncCommand`,
      { 'requestsIds': requests, 'adjustmentRequestsIds': adjustmentRequests, async: 'true' },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.$msgbox({
        type: 'info',
        message: 'Проставление даты ввода в эксплуатацию запущено'
      })
    })
  }
}
